:root {
    /* Colors */
    --amplify-font-family: 'Arial', sans-serif;
    --amazonOrange: #009900;
    /* Redefined to dark green */
    --lightAmazonOrange: #FFAC31;
    --darkAmazonOrange: #E88B01;
    --squidInk: #232F3E;
    --lightSquidInk: #31465F;
    --deepSquidInk: #152939;
    --grey: #828282;
    --lightGrey: #C4C4C4;
    --silver: #E1E4EA;
    --darkBlue: #31465F;
    --red: #DD3F5B;
    --white: #FFFFFF;
    --light-blue: #00a1c9;
}
amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Arial', sans-serif;
    min-width: 80vw;
    padding: 10vmin;

    @media only screen and (min-device-width: 700px) {
        margin: auto;
        padding: 15vmin;
        min-width: 100%;
    };

    --amplify-font-family: $typography-font-family;
    /* --amplify-primary-color: #FA3336; */
}
amplify-button {
    --background-color: var(--amplify-primary-color);
    --background-color-active: var(--amplify-primary-shade);
    --background-color-disable: var(--amplify-primary-tint);
    --color: var(--amplify-primary-contrast);
    --border-width: 0;
    --border-color: initial;
    --border-style: initial;
    --link-color: var(--amplify-primary-color);
    --link-hover: var(--amplify-primary-tint);
    --link-active: var(--amplify-primary-shade);
    --text-transform: uppercase;
    --icon-fill: var(--amplify-white);
    --icon-height: 1.25rem;
    --padding: 1rem;
    --width: 100%;
    --font-wight:300;
    width: var(--width);
    text-align: center;
}